import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";

export function AttendenceComponent() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [storedUserData, setStoredUserData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear().toString()); 
    const [month, setMonth] = useState((new Date().getMonth() + 1).toString()); 
    const [dataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            setStoredUserData(userData);
        } else {
            console.log('User data not found in sessionStorage');
        }
    }, []);

    const yearOptions = [
        { value: '2024', label: '2024' },
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' },
    ];

    const monthOptions = [
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}rcservice/getempattendance?regid=${storedUserData.RegId}&superid=${storedUserData.SuperId}&year=${year}&month=${month}`);
                if (response.ok) {
                    const data = await response.json();
                    setAttendanceData(data['ResultData']);
                } else {
                    console.error('Failed to fetch attendance data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching attendance data:', error.message);
            } finally {
                setDataLoading(false);
            }
        };

        if (storedUserData.RegId && storedUserData.SuperId) {
            fetchData();
        }
    }, [storedUserData, year, month]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDataLoading(true);

        try {
            const response = await fetch(`${API_BASE_URL}rcservice/getempattendance?regid=${storedUserData.RegId}&superid=${storedUserData.SuperId}&year=${year}&month=${month}`);
            if (response.ok) {
                const data = await response.json();
                setAttendanceData(data['ResultData']);
                console.log("result data:", data);
            } else {
                console.error('Failed to fetch attendance data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching attendance data:', error.message);
        }

        setDataLoading(false);
    };

    const currentItems = attendanceData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="toolbar " id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                            <div className="page-title d-flex flex-column">
                                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                                    Attendance
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                        <div className="content flex-row-fluid pt-1" id="kt_content">
                            <div className="card">
                                <div className="card-header border-0 pt-6">
                                    <div className="card-title row">
                                        <form className='d-flex flex-wrap' onSubmit={handleSubmit}>
                                            <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                                                <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="course">Year</label>
                                                <Select
                                                    placeholder="Choose Year"
                                                    style={{ width: 140 }}
                                                    options={yearOptions}
                                                    value={year}
                                                    onChange={(value) => setYear(value)}
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                                                <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="course">Month</label>
                                                <Select
                                                    placeholder="Choose Month"
                                                    style={{ width: 140 }}
                                                    options={monthOptions}
                                                    value={month}
                                                    onChange={(value) => setMonth(value)}
                                                />
                                            </div>
                                            <div class="col-6 col-lg-3 mt-10 mx-2 w-100px">
                                                <button type="submit" class="btn btn-primary h-35px h-lg-33px me-2" style={{ marginTop: "5px", paddingTop: "5px", paddingBottom: "5px" }}>Search</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="table-responsive">
                                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                                            <thead>
                                                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                                    <th className="w-10px pe-2">SL.NO</th>
                                                    <th className="min-w-125px">Date</th>
                                                    <th className="min-w-125px">Shift</th>
                                                    <th className="min-w-125px">In Time</th>
                                                    <th className="min-w-125px">Out Time</th>
                                                    <th className="min-w-125px">Duration</th>
                                                    <th className="min-w-125px">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-start fw-semibold text-gray-600">
                                                {dataLoading ? (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <p className='loader text-center'>loading...</p>
                                                        </td>
                                                    </tr>
                                                ) : currentItems.length > 0 ? (
                                                    currentItems.map((item, index) => (
                                                        <tr key={index}>
                                                            <th>{(currentPage - 1) * itemsPerPage + index + 1}</th>
                                                            <td>{item.Date}</td>
                                                            <td>{item.ActualShift ? item.ActualShift : 'N/A'}</td>
                                                            <td>{item.InTime ? item.InTime : 'N/A'}</td>
                                                            <td>{item.OutTime ? item.OutTime : 'N/A'}</td>
                                                            <td>{item.Duration}</td>
                                                            <td className={
                                                                item.Status === "Present" ? "text-success fw-semibold" :
                                                                    item.Status === "Leave" ? "text-danger fw-semibold" :
                                                                        item.Status === "Late" ? "text-info fw-semibold" :
                                                                            item.Status === "No Swipe" ? "text-warning fw-semibold" :
                                                                                item.Status === "Week Off" ? "text-primary fw-semibold" :
                                                                                    item.Status === "Missed Swipe" ? "text-primary fw-semibold" : ""
                                                            }>
                                                                {item.Status}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7} className='text-center'>No attendance data available for the selected year and month.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        itemsPerPage={itemsPerPage}
                                        totalItems={attendanceData.length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
