import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";
import { API_BASE_URL } from "./Config";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export function ManualAttandanceComponent() {
  const [storedUserData, setStoredUserData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamAttendance, setTeamAttendance] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [message, setMessage] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [swipes, setSwipes] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedShift, setSelectShift] = useState([]);
  const [editAttendanceData, setEditAttendanceData] = useState({
    SuperId: "",
    RegId: "",
    Reason: "",
    InStatus: "",
    OutStatus: "",
    DayInOutId: "",
    DateOfTransaction: "",
    ActualShiftId: "",
    DaySwipes: [{
      DaySwipesId: "",
      InTime: "",
      OutTime: "",
    },]

  });

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teamAttendance.slice(indexOfFirstItem, indexOfLastItem);
  console.log(currentItems)
  console.log("team", teamAttendance)

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);



  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (storedUserData?.RegId) {
        try {
          const response = await fetch(`
            ${API_BASE_URL}team/getmyteam?regid=${storedUserData.RegId}`
          );
          const data = await response.json();

          setTeamMembers(data["ResultData"]);
          setLoading(true);
        } catch (error) {
          console.error("Error fetching team members:", error);
        }

      }
    };
    fetchTeamMembers();
  }, [storedUserData?.UserName]);

  const handleEmpChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const startDate = e.target.elements.startDate.value;
    const employeeId = selectedEmployee || '';


    try {
      const response = await fetch(`
        ${API_BASE_URL}rcservice/getDayInOutDetailsEMPManager?regid=${employeeId}&ManagerId=${storedUserData.RegId}&date=${startDate}
      `);
      if (response.ok) {
        const data = await response.json();
        if (data.Status) {
          console.log("team att:", data);
          setTeamAttendance(data["ResultData"]);
          setDate(data.ResultData[0].DateOfTransaction);
          setMessage("");
        } else {
          setTeamAttendance([]);
          setMessage("No Data Available..");
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchSwipes = async () => {
      const employeeId = selectedEmployee;
      if (storedUserData?.RegId && storedUserData?.SuperId) {
        try {
          const response = await fetch(`
            ${API_BASE_URL}rcservice/getSwipes?regid=${employeeId}&superid=${storedUserData.SuperId}&date=${date}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          console.log("team swipes:", data);
          setSwipes(data.ResultData);
          console.log(data.ResultData);
        } catch (error) {
          console.log(error.message);
        } finally {
          setLoading(false);
        }

      }
    };

    fetchSwipes();
  }, [storedUserData.RegId, storedUserData.SuperId, date])





  const outStatusOptions = [
    { value: "P", label: "Present" },
    { value: "A", label: "Absent" },
    { value: "L", label: "Late" },
    { value: "D", label: "On Duty" },
    { value: "WO", label: "Week Off" },
  ];
  const inStatusOptions = [
    { value: "P", label: "Present" },
    { value: "A", label: "Absent" },
    { value: "L", label: "Late" },
    { value: "WO", label: "Week Off" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    const fetchShifts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}rcservice/getshifts?superid=${storedUserData.SuperId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("testing data:", data)
        const options = data.ResultData.map(shift => ({
          value: shift.id,
          label: shift.Name,

        }));
        console.log("fghjkl", options)
        setShiftOptions(options)

      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
      console.log("shiftopt:", shiftOptions)

    };

    fetchShifts();
  }, [storedUserData.SuperId]);


  const handleShiftChange = (selectedOption) => {
    setEditAttendanceData({
      ...editAttendanceData,
      ActualShiftId: selectedOption.value,
    });
  };

  const handleEditAttendance = (attendance) => {
    console.log("id:", attendance.Id);
    console.log(attendance);
    console.log("swipes", swipes);
    console.log("shift", shiftOptions);

    if (swipes && swipes.DaySwipes && swipes.DaySwipes.length > 0) {
      setEditAttendanceData(prevState => ({
        ...prevState,
        SuperId: attendance.SuperId,
        RegId: attendance.RegId,
        Reason: swipes.Reason,
        DayInOutId: swipes.DayInOutId,
        DateOfTransaction: swipes.DateOfTransaction,
        InStatus: swipes.InStatus,
        OutStatus: swipes.OutStatus,
        ActualShiftId: swipes.ActualShiftId,
        DaySwipes: swipes.DaySwipes.map(swipe => ({
          DaySwipesId: swipe.DaySwipesId || "",
          InTime: swipe.InTime || "",
          OutTime: swipe.OutTime || "",
        })),
      }));
    } else {
      console.log("No swipes present, skipping update.");
    }

    // Note: `editAttendanceData` might not reflect the new state immediately due to async nature of setState.
    console.log("edit:", editAttendanceData);
  };


  useEffect(() => {
    setEditAttendanceData((prevState) => ({
      ...prevState,
      DaySwipes: prevState.DaySwipes.length === 0
        ? [{ DaySwipesId: "", InTime: "", OutTime: "" }]
        : prevState.DaySwipes,
    }));
  }, []);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("edit data:", editAttendanceData);

    try {
      const response = await fetch(`${API_BASE_URL}rcservice/updateManualAttendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ inputdata: editAttendanceData }),
      });
      const responseData = await response.json();
      if (response.ok) {
        console.log("Attendance updated successfully:", responseData);

        Swal.fire({
          title: "Success!",
          text: responseData.Message || "Attendance updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/ManualAttendance');
            window.location.reload();
          }
        });

      } else {
        console.error("Failed to update attendance:", responseData);

        Swal.fire({
          title: "Error!",
          text: `Failed to update attendance. Server responded with: ${responseData.Message || "Unknown error"}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }

    } catch (error) {
      console.error("Error updating attendance:", error);
      Swal.fire({
        title: "Error!",
        text: `An unexpected error occurred: ${error.message || "Unknown error"}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }

  };

  return (
    <>
      <HeaderComponent></HeaderComponent>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    ManualAttendance
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header w-100 border-0 pt-6">
                    <form className="w-75" onSubmit={handleSubmit}>
                      <div className="card-title w-100 row">
                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="start_date"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            id="start_date"
                            className="form-control form-control-solid w-200px ps-3"
                            name="startDate"
                          ></input>
                        </div>
                        {/* <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="end_date"
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            id="end_date"
                            className="form-control form-control-solid w-200px ps-3"
                            name="endDate"
                          ></input>
                        </div> */}
                        <div className="col-6 col-lg-2 d-flex flex-column align-items-start  my-1 mx-3 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="course"
                          >
                            Employee
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Status"
                            id="course"
                            value={selectedEmployee}
                            onChange={handleEmpChange}
                            options={teamMembers.map((option) => {
                              return {
                                value: option.RegId,
                                label: option.UserName,
                              };
                            })}
                          />
                        </div>
                        <div className="col-6 col-lg-3 mt-10 mx-2 w-100px">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-32px"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="offcanvas offcanvas-start"
                    tabIndex="-1"
                    id="applyLeaveOffCanvas1"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header bg-secondary">
                      <h5
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Edit attendance
                      </h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body p-0">
                      <form className="row p-7" onSubmit={handleEditSubmit}>
                        <div className="col-6 d-flex flex-column dropdown mt-3 ">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3 text-start"
                            htmlFor="start_date"
                          >
                            {" "}
                            Date
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-solid w-1500px ps-3"
                            name="date"
                            value={date}
                            onChange={handleDateChange}
                          ></input>
                        </div>

                        <div className="col-6 d-flex flex-column dropdown mt-5">
                          <label
                            htmlFor="ActualShift"
                            className="required form-label mb-3 text-start"
                          >
                            ActualShift
                          </label>
                          <Select
                            placeholder="Choose Type"
                            value={editAttendanceData?.ActualShiftId}
                            options={shiftOptions}
                            onChange={handleShiftChange}
                            style={{ width: 160 }}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column dropdown mt-3 ">
                          <label className="required form-label mb-3 text-start">
                            In Status
                          </label>
                          <Select
                            style={{ width: 160 }}
                            placeholder="Choose Type"
                            value={editAttendanceData.InStatus}
                            onChange={(value) =>
                              setEditAttendanceData((prevState) => ({
                                ...prevState,

                                InStatus: value,
                              }))
                            }
                            options={inStatusOptions}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column dropdown mt-3 ">
                          <label
                            htmlFor="out status"
                            className="required form-label mb-3 text-start"
                          >
                            Out Status
                          </label>
                          <Select
                            style={{ width: 160 }}
                            // placeholder="Gender"
                            placeholder="Choose Type"
                            value={editAttendanceData.OutStatus}
                            onChange={(value) =>
                              setEditAttendanceData((prevState) => ({
                                ...prevState,
                                OutStatus: value,
                              }))
                            }
                            options={outStatusOptions}
                          />
                        </div>
                        <>
                          {editAttendanceData.DaySwipes.length > 0 ? (
                            <div className="d-flex flex-column">
                              {/* Display In Time for the first entry */}
                              <div className="d-flex mt-3">
                                <div className="col-6 d-flex flex-column dropdown">
                                  <label htmlFor={`in-time-0`} className="required form-label mb-3 text-start">
                                    In Time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control form-control-solid w-150px ps-3"
                                    name={`InTime-0`}
                                    value={editAttendanceData.DaySwipes[0].InTime || ""}
                                    onChange={(e) =>
                                      setEditAttendanceData((prevState) => {
                                        const newDaySwipes = [...prevState.DaySwipes];
                                        newDaySwipes[0] = { ...newDaySwipes[0], InTime: e.target.value };
                                        return { ...prevState, DaySwipes: newDaySwipes };
                                      })
                                    }
                                  />
                                </div>

                                {/* Display Out Time for the last entry */}
                                <div className="col-6 d-flex flex-column dropdown">
                                  <label
                                    htmlFor={`out-time-0`}
                                    className="required form-label mb-3 text-start"
                                  >
                                    Out Time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control form-control-solid w-150px ps-3"
                                    name={`OutTime-0`}
                                    value={editAttendanceData.DaySwipes[0].OutTime || ""}
                                    onChange={(e) =>
                                      setEditAttendanceData((prevState) => {
                                        const newDaySwipes = [...prevState.DaySwipes];
                                        newDaySwipes[0] = { ...newDaySwipes[0], OutTime: e.target.value };
                                        return { ...prevState, DaySwipes: newDaySwipes };
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              {/* Display additional breaks (excluding the first entry) */}
                              {editAttendanceData.DaySwipes.slice(1).map((swipe, index) => (
                                <div key={index + 1} className="d-flex mt-3">
                                  {/* In Time for subsequent entries */}
                                  <div className="col-6 d-flex flex-column dropdown">
                                    <label
                                      htmlFor={`in-time-${index + 1}`}
                                      className="required form-label mb-3 text-start"
                                    >
                                      In Time
                                    </label>
                                    <input
                                      type="time"
                                      className="form-control form-control-solid w-150px ps-3"
                                      name={`InTime-${index + 1}`}
                                      value={swipe.InTime || ""}
                                      onChange={(e) =>
                                        setEditAttendanceData((prevState) => {
                                          const newDaySwipes = [...prevState.DaySwipes];
                                          newDaySwipes[index + 1] = { ...newDaySwipes[index + 1], InTime: e.target.value };
                                          return { ...prevState, DaySwipes: newDaySwipes };
                                        })
                                      }
                                    />
                                  </div>

                                  {/* Out Time for subsequent entries */}
                                  <div className="col-6 d-flex flex-column dropdown">
                                    <label
                                      htmlFor={`out-time-${index + 1}`}
                                      className="required form-label mb-3 text-start"
                                    >
                                      Out Time
                                    </label>
                                    <input
                                      type="time"
                                      className="form-control form-control-solid w-150px ps-3"
                                      name={`OutTime-${index + 1}`}
                                      value={swipe.OutTime || ""}
                                      onChange={(e) =>
                                        setEditAttendanceData((prevState) => {
                                          const newDaySwipes = [...prevState.DaySwipes];
                                          newDaySwipes[index + 1] = { ...newDaySwipes[index + 1], OutTime: e.target.value };
                                          return { ...prevState, DaySwipes: newDaySwipes };
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            // If there are no DaySwipes entries, render empty InTime and OutTime inputs
                            <div className="d-flex">
                              <div className="col-6 d-flex flex-column dropdown mt-3">
                                <label htmlFor="in-time" className="required form-label mb-3 text-start">
                                  In Time
                                </label>
                                <input
                                  type="time"
                                  className="form-control form-control-solid w-150px ps-3"
                                  name="InTime"
                                  value=""
                                  onChange={(e) =>
                                    setEditAttendanceData((prevState) => ({
                                      ...prevState,
                                      DaySwipes: [
                                        {
                                          DaySwipesId: "",
                                          InTime: e.target.value,
                                          OutTime: "",
                                        },
                                      ],
                                    }))
                                  }
                                />
                              </div>

                              <div className="col-6 d-flex flex-column dropdown mt-3">
                                <label htmlFor="out-time" className="required form-label mb-3 text-start">
                                  Out Time
                                </label>
                                <input
                                  type="time"
                                  className="form-control form-control-solid w-150px ps-3"
                                  name="OutTime"
                                  value=""
                                  onChange={(e) =>
                                    setEditAttendanceData((prevState) => ({
                                      ...prevState,
                                      DaySwipes: [
                                        {
                                          DaySwipesId: "",
                                          InTime: "",
                                          OutTime: e.target.value,
                                        },
                                      ],
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          )}

                          {/* Buttons */}
                          <div className="d-flex mt-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                setEditAttendanceData((prevState) => ({
                                  ...prevState,
                                  DaySwipes: [
                                    ...prevState.DaySwipes,
                                    {
                                      DaySwipesId: "", // Add a new blank break entry
                                      InTime: "",
                                      OutTime: "",
                                    },
                                  ],
                                }))
                              }
                            >
                              Add New Break
                            </button>

                            <button type="submit" className="btn btn-primary ms-5">
                              Save
                            </button>
                          </div>
                        </>

                      </form>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                SL.NO
                              </div>
                            </th>
                            <th className="min-w-125px">Employee</th>
                            <th className="min-w-125px">Actual Shift</th>
                            <th className="min-w-125px">In Time</th>
                            <th className="min-w-125px">Out Time</th>
                            <th className="min-w-125px">In Status</th>
                            <th className="min-w-125px">Out Status</th>
                            <th className="min-w-125px">Action</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600 text-start">
                          {loading ? (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <p className="loader">loading...</p>
                              </td>
                            </tr>
                          ) : currentItems && currentItems.length > 0 ? (
                            currentItems.map((attendance, index) => (
                              <tr key={index}>
                                {/* Serial number */}
                                <td>
                                  {(currentPage - 1) * itemsPerPage + index + 1}
                                </td>

                                {/* Attendance fields */}
                                <td>{attendance.UserName}</td>
                                <td>{attendance.ActualShiftName}</td>
                                <td>
                                  {attendance.InTime}
                                </td>
                                <td>
                                  {attendance.OutTime}
                                </td>
                                <td>{attendance.InStatus}</td>
                                <td>{attendance.OutStatus}</td>

                                {/* Button */}
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#applyLeaveOffCanvas1"
                                    aria-controls="applyLeaveOffCanvas"
                                    onClick={() =>
                                      handleEditAttendance(attendance)
                                    }
                                  >
                                    <i
                                      className="fa-solid fa-street-view text-white"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={12}>
                                No data available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={teamAttendance.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </>
  );
}