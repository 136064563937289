import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";
import { FooterComponent } from "./footer";
import { HeaderComponent } from "./header";



export function LeaveSummaryComponent() {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [storedUserData, setStoredUserData] = useState([]);
  const [lvSummaryData, setLvSummaryData] = useState([]);
  const [lvDetailsData, setLvDetailsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lvSummaryLoading, setLvSummaryLoading] = useState(false);
  const [lvDetailsLoading, setLvDetailsLoading] = useState(false);
  const [displayLvDetails, setDisplayLvDetails] = useState(false);
  const [displayLvSummary, setDisplayLvSummary] = useState(true);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  const yearOptions = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
  ]
  const currentItemsLvDet = lvDetailsData.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = lvSummaryData.slice(indexOfFirstItem, indexOfLastItem);
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleLvSummarySubmit = async (e) => {
    setDisplayLvDetails(false);
    setDisplayLvSummary(true);

    e.preventDefault();
    setLvSummaryLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}rcservice/getempleavesummary?regid=${storedUserData.RegId}&year=${selectedYear}`);
      if (response.ok) {
        const data = await response.json();
        if (data.Status) {
          setLvSummaryLoading(false);
          setLvSummaryData(data['ResultData']);
        } else {
          setLvSummaryLoading(false);
        }
      } else {
        setLvSummaryLoading(false);
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      setLvSummaryLoading(false);
      console.error('Error:', error);
    }
  };


  const handleLvDetailsSubmit = async (e) => {
    setDisplayLvDetails(true);
    setDisplayLvSummary(false);
    e.preventDefault();
    setLvDetailsLoading(true);
    console.log("year:", selectedYear)

    try {
      const response = await fetch(`${API_BASE_URL}rcservice/getempleavedetails?regid=${storedUserData.RegId}&year=${selectedYear}`);
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data)
        if (data.Status) {
          setLvDetailsLoading(false);
          setLvDetailsData(data['ResultData']);
        } else {
          setLvDetailsLoading(false);
        }
      } else {
        setLvDetailsLoading(false);
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      setLvDetailsLoading(false);
      console.error('Error:', error);
    }
  };

  const getStatusTextAndColor = (status) => {
    switch (status) {
      case 1:
        return { text: 'Pending', color: 'text-warning' };
      case 2:
        return { text: 'Approved', color: 'text-success' };
      case 3:
        return { text: 'Rejected', color: 'text-danger' };
      default:
        return { text: '', color: '' };
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (

    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div className="toolbar " id="kt_toolbar">
            <div
              id="kt_toolbar_container"
              className="container-xxl d-flex flex-stack flex-wrap"
            >
              <div className="page-title d-flex flex-column">
                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                  Leave Summary
                </h1>
              </div>
            </div>
          </div>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-xxl"
          >
            <div className="content flex-row-fluid pt-1" id="kt_content">
              <div className="card">
                <div className="card-header border-0 pt-6">
                  <div className="card-title row">
                    <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                      <label
                        className="fs-6 fw-semibold mt-2 mb-3"
                        htmlFor="course"
                      >
                        Year
                      </label>
                      <Select
                        style={{ width: 140 }}
                        options={yearOptions}
                        placeholder="Select Year"
                        name="year"
                        value={selectedYear}
                        onChange={handleYearChange}
                        required

                      >

                      </Select>
                    </div>
                    <div className="col-6 col-lg-3 mt-10 mx-3 w-160px">
                      <button
                        type="submit"
                        className="btn btn-primary d-flex flex-center h-30px h-lg-35px w-150px"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={handleLvSummarySubmit}
                      >
                        Leave Summary
                      </button>
                    </div>

                    <div className="col-6 col-lg-3 mt-9 mx-3 w-160px">
                      <button
                        type="submit"
                        className="btn btn-primary d-flex flex-center h-30px h-lg-35px w-150px"
                        onClick={handleLvDetailsSubmit}
                      >
                        Leave Details
                        {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                      </button>
                    </div>
                  </div>

                </div>
                <div className={`card-body pt-0 ${displayLvSummary ? "d-block" : 'd-none'}`}>
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_customers_table"
                    >
                      <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                          <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                              SL.NO
                            </div>
                          </th>
                          <th className="min-w-125px">
                            Leave Type
                          </th>
                          <th className="min-w-125px">Carry Forward</th>
                          <th className="min-w-125px">Accured</th>
                          <th className="min-w-125px"> Taken</th>
                          <th className="min-w-125px">Balance</th>





                        </tr>
                      </thead>
                      <tbody className=" text-start fw-semibold text-gray-600">
                        {lvSummaryLoading ? (
                          <tr>
                            <td colSpan={12} className='text-center'>
                              <p className='loader'>loading....</p>
                            </td>
                          </tr>
                        ) : currentItems.length > 0 ? (
                          currentItems.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{item.LeaveTypeName}</td>
                              <td className='text-center text-success fw-bold'>{item.CarryForwardLeaves}</td>
                              <td className='text-center text-primary fw-bold'>{item.Accrued}</td>
                              <td className='text-center text-danger fw-bold'>{item.Taken}</td>
                              <td className='text-center text-warning fw-bold'>{item.Balance}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className='text-center' colSpan={12}>
                              No data available.
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={lvSummaryData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>

                <div className={`card-body pt-0 ${displayLvDetails ? "d-block" : 'd-none'}`}>
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_customers_table"
                    >
                      <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                          <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                              SL.NO
                            </div>
                          </th>

                          <th className="min-w-125px" >Name</th>
                          <th className="min-w-125px">Leave Type</th>
                          <th className="min-w-125px">Start Date</th>
                          <th className="min-w-125px">End Date</th>
                          <th className="min-w-125px">Comments</th>
                          <th className="min-w-125px">Status</th>
                        </tr>
                      </thead>
                      <tbody className=" text-start fw-semibold text-gray-600">
                        {lvSummaryLoading ? (
                          <tr>
                            <td colSpan={12} className='text-center'>
                              <p className='loader'> loading....</p>
                            </td>
                          </tr>
                        ) : currentItemsLvDet.length > 0 ? (
                          currentItemsLvDet.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{item.UserName}</td>
                              <td className='text-success fw-bold'>{item.LvType}</td>
                              <td className=' text-primary fw-bold'>{formatDate(item.StartDt)}</td>
                              <td className=' text-danger fw-bold'>{formatDate(item.EndDt)}</td>
                              <td className=' text-warning fw-bold'>{item.Comments ? item.Comments : 'N/A'}</td>
                              <td className=' text-success fw-bold'>{item.Status}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className='text-center' colSpan={12}>
                              No data available.
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={lvDetailsData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}