import { useEffect, useState } from "react";
import Pagination from "./Pagination";

import { API_BASE_URL } from "./Config";
import Swal from "sweetalert2";
import { Select } from "antd";


export function LeavesComponent() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [storedUserData, setStoredUserData] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [status, setStatus] = useState("all");
  const [dataLoading, setDataLoading] = useState(false);
  const [leavesData, setLeavesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [halfDay, setHalfDay] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [comments, setComments] = useState('');
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveCategory, setLeaveCategory] = useState('');
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);


  const yearOptions = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const leaveTypeOptions = leaveTypes.map(leaveType => ({
    value: leaveType.LvTypeId,
    label: leaveType.LvType
  }));

  const statusOptions = [
    { value: "1", label: "Pending" },
    { value: "2", label: "Approved" },
    { value: "3", label: "Rejected" },
  ];

  const currentItems = leavesData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    if (storedUserData?.RegId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}rcservice/getempleaves?regid=${storedUserData.RegId}&year=&status=`);
          if (response.ok) {
            const data = await response.json();
            setLeavesData(data['ResultData']);
          } else {
            console.error('Failed to fetch leaves data:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching leaves data:', error.message);
        }
      };
      fetchData();
    }
  }, [storedUserData?.RegId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Year:", year);
    console.log("Status:", status);
    setDataLoading(true);

    const url = `${API_BASE_URL}rcservice/getempleaves?regid=${storedUserData.RegId}&year=${year}&status=${status}`;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setDataLoading(false);
        setLeavesData(data["ResultData"]);
      } else {
        setDataLoading(false);
        console.error("Failed to fetch attendance data:", response.statusText);
      }
    } catch (error) {
      setDataLoading(false);
      console.error("Error fetching attendance data:", error.message);
    }
  };

  const handleLeaveTypeChange = selectedOption => {
    setSelectedLeaveType(selectedOption);
    console.log("select:", selectedOption);
    if (selectedOption) {
      console.log('Selected leave type ID:', selectedOption);
      setLeaveCategory(selectedOption.value);
    }
  };

  const calculateLeaveCount = (startDate, endDate, halfDay) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const timeDifference = endDateObj - startDateObj;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    let leaveCount = 0;

    if ((startDate === endDate) && halfDay) {
      leaveCount = 0.5;
    }
    else if (startDate === endDate) {
      leaveCount = 1;
    }
    else {
      if (halfDay) {
        leaveCount = daysDifference + 0.5;
      } else {
        leaveCount = daysDifference;
      }
    }
    console.log(leaveCount, 'final count');
    return leaveCount;
  };

  useEffect(() => {
    if (storedUserData && storedUserData.SuperId) {
      const fetchLeaveTypes = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}rcservice/getleavetype?superid=${storedUserData.SuperId}`);
          const data = await response.json();
          console.log(data)
          setLeaveTypes(data['ResultData']);
        } catch (error) {
          console.error('Error fetching leave types:', error);
        }
      };
      fetchLeaveTypes();
    }
  }, [storedUserData && storedUserData.SuperId]);

 

  const handleLeaveSubmit = async (e) => {

    e.preventDefault();
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    
    let leaveCount = Math.ceil((end - start) / (1000 * 3600 * 24)) + 1;
    
    // Subtract 0.5 if halfDay is selected
    leaveCount -= halfDay ? 0.5 : 0;
    
    // Now validate the fields
    if (!startDate || !endDate || !comments || !selectedLeaveType) {
      alert('Select required fields.');
    }
    else {
      setLoading(true);
      const leaveData = {
        inputdata: {
          superid: storedUserData.SuperId,
          regid: storedUserData.RegId,
          lvtypeid: selectedLeaveType,
          halfday: leaveCount,
          startdate: startDate,
          enddate: endDate,
          leavecount: leaveCount ,
          comments: comments,
          createdby: storedUserData.RegId
        }
      };
      console.log("leave data", leaveData)

      try {

        console.log('checking try')

        const response = await fetch(`${API_BASE_URL}rcservice/saveleave`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ inputdata: leaveData['inputdata'] }),
        });

        // Handle the response accordingly
        const result = await response.json();
        if (result.Status) {
          // setSubmitBtnLoading(false);

          Swal.fire({
            title: "Success",
            text: `${result.Message}`,
            icon: "success",
          }).then((result) => {

            if (result.isConfirmed || result.isDismissed) {
                window.location.reload();

 
            }
          });
        } else {

          Swal.fire({
            title: "Error",
            text: `${result.Message}`,
            icon: "error",
          });
        }
        console.log('Leave submission result:', result);

        // You may want to handle success or error scenarios here
      } catch (error) {
        console.error('Error submitting leave:', error);
      }
    }
  };
  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div className="toolbar  " id="kt_toolbar">

            <div
              id="kt_toolbar_container"

              className="app-container container-xxl d-flex flex-stack flex-wrap"
            >

              <div className="page-title d-flex flex-column">
                <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                  Leaves
                </h1>
              </div>
              <div className={`${showOffcanvas ? 'show' : ''}`}>
                {/* Button to trigger the off-canvas */}
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#applyLeaveOffCanvas"
                  aria-controls="applyLeaveOffCanvas"
                  onClick={() => setShowOffcanvas(true)}
                >
                  Apply Leave
                </button>

                {/* Off-canvas component */}
                <div
                  className="offcanvas offcanvas-start"
                  tabIndex="-1"
                  id="applyLeaveOffCanvas"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header bg-secondary">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                      Apply Leave
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() => setShowOffcanvas(false)}
                    ></button>
                  </div>
                  <div className="offcanvas-body p-0">
                    <form className="row p-7" onSubmit={handleLeaveSubmit}>
                      <div className="col-6 d-flex flex-column dropdown mt-3 ">
                        <label
                          htmlFor="gender"
                          className="required form-label mb-3 text-start"
                        >
                          Type
                        </label>
                        <Select
                          style={{ width: 160 }}
                          // placeholder="Gender"
                          placeholder="Choose Type"
                          options={leaveTypeOptions}
                          value={selectedLeaveType}
                          onChange={handleLeaveTypeChange}
                        />
                      </div>

                      <div className="col-6 d-flex flex-column dropdown mt-3">
                        <label htmlFor="halfDayLeave" className="required form-label mb-3 text-start">
                          Half Day Leave
                        </label>
                        <input
                          type="checkbox"
                          id="halfDayCheckbox"
                          name="half_day_leave"
                          className="form-check-input"
                          checked={halfDay}
                          onChange={(e) => setHalfDay(e.target.checked)}
                        />
                      </div>

                      <div className="col-6 d-flex flex-column dropdown mt-3">
                        <label
                          htmlFor="date"
                          className="required form-label mb-3 text-start"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          style={{ width: 160 }}
                          className="form-control form-control-sm form-control-solid"
                          placeholder=""
                          id="date"
                          name="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required

                        />
                      </div>

                      <div className="col-6 d-flex flex-column dropdown mt-3 text-start">
                        <label
                          htmlFor="date"
                          className="required form-label mb-3 text-start"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          style={{ width: 160 }}
                          className="form-control form-control-sm form-control-solid text-align-start"
                          placeholder=""
                          id="date"
                          name="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}


                        />
                      </div>
                      <div className="col-12 mb-5 fv-row">
                        <label
                          htmlFor="address"
                          className="required form-label mb-3 text-start"
                        >
                          Reason
                        </label>

                        <textarea
                          required
                          className="form-control form-control-sm form-control-solid"
                          placeholder=""
                          id="address"
                          name="address"
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}

                        />
                      </div>

                      <div class="col-6 col-lg-3 mt-10 mx-2 w-100px">
                        <button type="submit" class="btn btn-primary h-35px h-lg-33px me-2" aria-label="Close" style={{ marginTop: "10px", paddingTop: "5px", paddingBottom: "5px" }}>
                        {loading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-xxl"
          >
            <div className="content flex-row-fluid pt-1" id="kt_content">
              <div className="card">
                <div className="card-header border-0 pt-6">
                  <div className="card-title row">
                    <form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="course"
                        >
                          Year
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Year"
                          id="year"
                          optionFilterProp="label"
                          options={yearOptions}
                          value={year}
                          onChange={(selectedOption) => setYear(selectedOption)}

                        >

                        </Select>
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="status"
                        >
                          Status
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Status"
                          id="status"
                          optionFilterProp="label"
                          options={statusOptions}
                          value={status}
                          onChange={(selectedOption) => setStatus(selectedOption)}

                        >

                        </Select>
                      </div>

                     &nbsp;&nbsp;&nbsp;

                      <div className="col-6 col-lg-3 mt-10 mx-2 w-100px">
                        <button
                          type="submit"
                          className="btn btn-primary d-flex flex-center h-24px h-lg-35px me-2"style={{marginTop: "5px", paddingTop: "5px",paddingBottom: "5px"}}
                        >
                          Search
                        </button>
                      </div>

                    </form>
                  </div>

                </div>
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_customers_table"
                    >
                      <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                          <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                              SL.NO
                            </div>
                          </th>
                          <th className="min-w-125px"> Start Date</th>
                          <th className="min-w-125px">End Date</th>
                          <th className="min-w-125px">
                            Type
                          </th>
                          <th className="min-w-125px">Count</th>
                          <th className="min-w-125px">Reason</th>
                          <th className="min-w-125px">Status</th>
                          <th className="min-w-125px">Comments</th>

                        </tr>
                      </thead>
                      <tbody className=" text-start sfw-semibold text-gray-600">
                        {dataLoading ? (
                          <tr>
                            <td className="text-center" colSpan="12">
                              <p className="loader"> loading.....</p>
                            </td>
                          </tr>
                        ) : currentItems.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan="12">
                              No data available
                            </td>
                          </tr>
                        ) : (
                          currentItems.map((item, index) => (
                            <tr key={index}>
                              <th>{(currentPage - 1) * itemsPerPage + index + 1}</th>
                              <td>{item.StartDt}</td>
                              <td>{item.EndDt}</td>
                              <td>{item.LvType}</td>
                              <td>{item.LeaveCount}</td>
                              <td>{item.Comments}</td>
                              <td className="fw-semibold fs-5 text-start">
                                {item.Status === 1 ? (
                                  <span className="text-warning badge">Pending</span>
                                ) : item.Status === 2 ? (
                                  <span className="text-success badge">Approved</span>
                                ) : item.Status === 3 ? (
                                  <span className="text-danger badge">Rejected</span>
                                ) : 'N/A'}
                              </td>
                              <td>{item.Reason}</td>
                            </tr>
                          ))
                        )}

                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={leavesData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
                <div
                  className="offcanvas offcanvas-end"
                  tabIndex="-1"
                  id="offcoffcanvasExampleanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                  style={{ width: "500px" }}
                >

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}