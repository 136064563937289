import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function HeaderComponent() {
  const [storedUserData, setStoredUserData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);


  const handleLogout = () => {
    sessionStorage.removeItem('userData');
    navigate('/');
  };

  return (

    <div
      data-kt-name="metronic"
      id="kt_app_body"
      data-kt-app-layout="dark-sidebar"
      data-kt-app-header-fixed="true"
      data-kt-app-sidebar-enabled="true"
      data-kt-app-sidebar-fixed="true"
      data-kt-app-sidebar-hoverable="true"
      data-kt-app-sidebar-push-header="true"
      data-kt-app-sidebar-push-toolbar="true"
      data-kt-app-sidebar-push-footer="true"
      data-kt-app-toolbar-enabled="true"
      className="app-default"
    >
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

          {/* Full-width Header */}
          <div className="bg-white d-flex align-items-center justify-content-between" style={{ width: "100%", padding: "0", margin: "0" }}>

            {/* Left side: Logo */}
            <div className="d-flex align-items-center" style={{ flex: 1 }}>
              <Link to="/profile">
                <img
                  alt="Logo"
                  src="assets/images/rollcall.jpg"
                  style={{ height: "66px", width: "auto", maxWidth: "100%" }}
                />
              </Link>
            </div>

            {/* Right side: User avatar and icons */}
            <div className="d-flex align-items-center">
              <div
                className="app-navbar-item ms-1 ms-lg-3"
                style={{
                  backgroundColor: '#fff',
                  border: '1px solidz  #ccc',    // border color and thickness
                  borderRadius: '5px',         // rounded corners (optional)
                  padding: '10px',             // space inside the border
                  display: 'inline-block'      // ensures it wraps content properly
                }}
              >
                <Link to="/profile">
                  <i
                    className="fas fa-home"
                    style={{
                      fontSize: '24px',
                      color: 'rgb(21 146 216)'
                    }}
                  ></i>
                </Link>
              </div>


              <div className="d-flex align-items-center ms-2 ms-lg-1 mx-5">
                <a
                  className="btn btn-flex flex-center btn-primary align-self-center px-0 px-md-3 h-30px w-30px w-md-auto h-lg-40px ms-1 ms-lg-2" // Reduced ms-2 to ms-1 and ms-lg-4 to ms-lg-2
                  onClick={handleLogout}
                >
                  <i className="fa-solid fa-right-from-bracket"></i>
                  <span className="d-none d-md-inline ms-2">Logout</span>
                </a>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

