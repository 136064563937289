import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";
import { FooterComponent } from "./footer";
import { HeaderComponent } from "./header";
import { useNavigate } from "react-router-dom";

export function MonthlyAttandanceComponent() {
  const [storedUserData, setStoredUserData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamAttendance, setTeamAttendance] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [designation, setDessignation] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [hasAccess, setHasAccess] = useState(true);
  const itemsPerPage = 8;



  const navigate = useNavigate();
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);


  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (storedUserData?.RegId) {
        try {
          const response = await fetch(
            `${API_BASE_URL}team/getmyteam?regid=${storedUserData.RegId}`
          );
          const data = await response.json();
          setTeamMembers(data['ResultData']);
        } catch (error) {
          console.error("Error fetching team members:", error);
        }
      }
    };

    fetchTeamMembers();
  }, [storedUserData?.UserName]);

  const yearOptions = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
  ];

  const monthOptions = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Month:', selectedMonth);
    console.log('Year:', selectedYear);
    console.log('Employee ID:', selectedEmployee);

    if (!selectedMonth || !selectedYear || !selectedEmployee) {
      setMessage('Please fill out all fields');
      return;
    }

    setLoading(true);
    const month = parseInt(selectedMonth);
    const year = parseInt(selectedYear);
    const employeeId = parseInt(selectedEmployee);

    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);

    const formattedStartDate = `${startDate.getFullYear()}-${(month < 10 ? '0' : '') + month}-${(startDate.getDate() < 10 ? '0' : '') + startDate.getDate()}`;
    const formattedEndDate = `${endDate.getFullYear()}-${(month < 10 ? '0' : '') + month}-${(endDate.getDate() < 10 ? '0' : '') + endDate.getDate()}`;

    try {
      const response = await fetch(`${API_BASE_URL}team/getmyteamattendance?regid=${storedUserData.RegId}&emplregid=${employeeId}&stdate=${formattedStartDate}&enddate=${formattedEndDate}`);
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data)
        if (data.Status) {
          setTeamAttendance(data['ResultData']);
          setMessage('');
        } else {
          setTeamAttendance([]);
          setMessage('No Data Available..');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error fetching data');
    } finally {
      setLoading(false);
    }
  };
  const handleEmpChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teamAttendance.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };


  return (
    <>
      <HeaderComponent></HeaderComponent>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Monthly Attendance
                  </h1>
                </div>
              </div>
            </div>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row w-75">
                      <form className="d-flex flex-wrap align-items-end" onSubmit={handleSubmit}>
                        <div className="col-12 col-lg-2 d-flex flex-column align-items-start my-1 p-2">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="year">
                            Year
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Year"
                            value={selectedYear}
                            onChange={setSelectedYear}
                            options={yearOptions}
                          />
                        </div>

                        <div className="col-12 col-lg-2 d-flex flex-column align-items-start my-1 p-2">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="month">
                            Month
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Month"
                            value={selectedMonth}
                            onChange={setSelectedMonth}
                            options={monthOptions}
                          />
                        </div>

                        <div className="col-12 col-lg-2 d-flex flex-column align-items-start my-1 p-2">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="employee">
                            Employee
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Employee"
                            value={selectedEmployee}
                            onChange={handleEmpChange}
                            options={teamMembers.map((option) => ({
                              value: option.RegId,
                              label: option.UserName,
                            }))}
                          />
                        </div>

                        <div className="col-12 col-lg-2 my-1 p-2 d-flex justify-content-start">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-31px w-80"
                          >
                            Search
                          </button>
                        </div>
                      </form>

                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                        <thead>
                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">SL.NO</th>
                            <th className="min-w-125px">Employee ID</th>
                            <th className="min-w-125px">Emp Name</th>
                            <th className="min-w-125px">Designation</th>
                            <th className="min-w-125px">Department</th>
                            <th className="min-w-125px">Date</th>
                            <th className="min-w-125px">Shift</th>
                            <th className="min-w-125px">In Time</th>
                            <th className="min-w-125px">Out Time</th>
                            <th className="min-w-125px">In Status</th>
                            <th className="min-w-125px">Out Status</th>
                          </tr>
                        </thead>
                        <tbody className="text-start">
                          {loading ? (
                            <tr>
                              <td colSpan={12} className='text-center'>
                                <p className='loader'></p>
                              </td>
                            </tr>
                          ) : currentItems.length > 0 ? (
                            currentItems.map((attendance, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{attendance.RegId}</td>
                                <td>{attendance.UserName}</td>
                                <td>{attendance.Designation}</td>
                                <td>{attendance.Dept}</td>
                                <td>{formatDate(attendance.DateOfTransaction)}</td>
                                <td>{attendance.ActualShiftName ? attendance.ActualShiftName : 'N/A'}</td>
                                <td>{attendance.FormatInTime ? attendance.FormatInTime : 'N/A'}</td>
                                <td>{attendance.FormatOutTime ? attendance.FormatOutTime : 'N/A'}</td>
                                <td className={
                                  attendance.InStCode === "Present" ? "text-success fw-semibold" :
                                    attendance.InStCode === "Leave" ? "text-danger fw-semibold" :
                                      attendance.InStCode === "Late" ? "text-info fw-semibold" :
                                        attendance.InStCode === "No Swipe" ? "text-warning fw-semibold" :
                                          attendance.InStCode === "Week Off" ? "text-primary fw-semibold" :
                                            attendance.InStCode === "Missed Swipe" ? "text-primary fw-semibold" : ""
                                }>
                                  {attendance.InStCode}
                                </td>
                                <td className={
                                  attendance.OutStCode === "Present" ? "text-success fw-semibold" :
                                    attendance.OutStCode === "Leave" ? "text-danger fw-semibold" :
                                      attendance.OutStCode === "Late" ? "text-info fw-semibold" :
                                        attendance.OutStCode === "No Swipe" ? "text-warning fw-semibold" :
                                          attendance.OutStCode === "Week Off" ? "text-primary fw-semibold" :
                                            attendance.OutStCode === "Missed Swipe" ? "text-primary fw-semibold" : ""
                                }>
                                  {attendance.OutStCode}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className='text-center' colSpan={12}>
                                No data available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {teamAttendance.length > itemsPerPage && (
                        <Pagination
                          itemsPerPage={itemsPerPage}
                          totalItems={teamAttendance.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      )}
                      {loading && <p>Loading...</p>}
                      {message && <p>{message}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent ></FooterComponent>
    </>
  );
}
