import { useEffect, useState } from "react";
import { API_BASE_URL } from "./Config";
import Pagination from "./Pagination";

export function HomeComponent() {
  const [storedUserData, setStoredUserData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [teamLeaves, setTeamLeaves] = useState([]);
  const [dates, setDates] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedActiveDate, setSelectedActiveDate] = useState(null);
  const [selectedCurentDate, setSelectedCurentDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const currentItems = teamLeaves.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    if (storedUserData?.RegId) {
      const fetchTeamLeaves = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}team/getmyteamleaves?regid=${storedUserData.RegId}&emplregid=&status=1&year=`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          const filteredData = data?.['ResultData']?.filter(item => item?.status === 1) || [];
          setTeamLeaves(filteredData);
        } catch (error) {
          console.error("Error fetching team members:", error);
        }
      };

      const fetchHolidays = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}rcservice/getholidays?superid=${storedUserData.RegId}`
          );
          const data = await response.json();
          if (data?.["Status"]) {
            const presentDate = new Date();
            const totalHolidaysData = data?.['ResultData'] || [];
            const futureHolidays = totalHolidaysData.filter((holiday) => {
              const holidayDate = new Date(holiday?.HolidayDt);
              return holidayDate > presentDate;
            });

            futureHolidays.sort(
              (a, b) => new Date(b?.HolidayDt) - new Date(a?.HolidayDt)
            );

            const lastFourHolidays = futureHolidays.slice(-4);
            setHolidays(lastFourHolidays);
          } else {
            setHolidays([]);
            console.error("Failed to fetch holidays:", response.statusText);
          }
        } catch (error) {
          setHolidays([]);
          console.error("Error fetching holidays:", error.message);
        }
      };
      fetchTeamLeaves();
      fetchHolidays();
    }
  }, [storedUserData?.RegId]);

  useEffect(() => {
    const getCurrentDate = () => {
      const currentDate = new Date();
      const currentDateString = currentDate.toDateString();
      setSelectedCurentDate(currentDate.getDate());
      return {
        day: currentDateString.slice(0, 3),
        date: currentDate.getDate(),
        fullDate: currentDate,
      };
    };

    const getLastSixDays = () => {
      const currentDate = new Date();
      return Array.from({ length: 10 }, (_, index) => {
        const previousDate = new Date();
        previousDate.setDate(currentDate.getDate() - index - 1);
        return {
          day: previousDate.toDateString().slice(0, 3),
          date: previousDate.getDate(),
          fullDate: previousDate,
        };
      });
    };

    const lastSixDays = getLastSixDays();
    const currentDate = getCurrentDate();
    setDates([currentDate, ...lastSixDays]);
  }, []);

  const safeReplace = (str) => (str ? str.replace(/-/g, ' ') : '');

  useEffect(() => {
    const fetch7DaysData = async () => {
      if (storedUserData && storedUserData.SuperId && storedUserData.RegId) {
        try {
          const response = await fetch(
            `${API_BASE_URL}rcservice/getemplast7daysattendance?superid=${storedUserData.SuperId}&regid=${storedUserData.RegId}`
          );
          const data = await response.json();
          console.log("attdendancedata:", data)
          const attendanceDataArray = Array.isArray(data.ResultData) ? data.ResultData : [];
          console.log("attendance:", attendanceDataArray);
          setAttendanceData(attendanceDataArray);
        } catch (error) {
          console.error("Error fetching attendance data:", error);
        }
      }
    };

    fetch7DaysData();
  }, [storedUserData.SuperId, storedUserData.RegId]);

  useEffect(() => {

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    const [day, month, year] = formattedDate.split("/");
    const desiredFormatDate = `${year}-${month}-${day}`;


    setSelectedDate(desiredFormatDate);
    setSelectedActiveDate(day);


    const filteredData = attendanceData.filter((item) => {
      return item.Date === formattedDate;
    });
    console.log(filterData)
    setFilterData(filteredData);
  }, [attendanceData]);

  const handleDateSelect = (selectedDate) => {
    const formattedDate = selectedDate.fullDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    const [day, month, year] = formattedDate.split("/");
    const desiredFormatDate = `${year}-${month}-${day}`;

    setSelectedDate(desiredFormatDate);
    setSelectedActiveDate(day);

    const filteredData = attendanceData.filter((item) => {
      return item.Date === formattedDate;
    });

    console.log(filteredData)

    setFilterData(filteredData);
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const colors = [
    "#20c997",
    "#6610f2",
    "#6f42c1",
    "#000000",
    "#ffc107",
    "#fd7e14",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timeString) => {
    const date = new Date(`1970-01-01T${timeString}`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="app-container container-xxl">
      <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
        <div className="card h-md-100">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">User Attendance</span>
            </h3>
          </div>
          <div className="card-body pt-7 px-0">
            <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex flex-wrap justify-content-between mb-8 px-3">
              {dates
                .slice()
                .reverse()
                .map((dayDate, index) => (
                  <li key={index} className="nav-item p-0 ms-0 mb-2">
                    <a
                      className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-3 px-2 btn-active-danger ${dayDate.date === selectedActiveDate ? "active" : ""
                        } ${dayDate.date === selectedCurentDate ? "border btn-danger border-3" : ""}`}
                      data-bs-toggle="tab"
                      onClick={() => handleDateSelect(dayDate)}
                    >
                      <span className="fs-7 fw-semibold">{dayDate.day}</span>
                      <span className="fs-6 fw-bold">{dayDate.date}</span>
                    </a>
                  </li>
                ))}
            </ul>

            {filterData.length > 0 && (
              <div className="d-flex flex-column">
                {filterData.map((attendance, index) => (
                  <div key={index} className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center flex-grow-1 me-5 mb-2">
                    <div className="text-gray-800 fw-semibold fs-4 text-start" style={{ marginLeft: '5px' }}>
                      In: <span className="text-success">{attendance.InTime ? attendance.InTime : 'NA'}</span> - Out: <span className="text-danger">{attendance.OutTime ? attendance.OutTime : 'NA'}</span>
                    </div>
                    <div className="text-gray-800 fw-semibold fs-4">
                      Shift: <span className="text-success" style={{ marginLeft: '5px' }}>{attendance.ActualShift ? attendance.ActualShift : 'NA'}</span>
                    </div>
                    <div className="text-gray-800 fw-semibold fs-4">
                      Status: <span className="fw-semibold text-primary" style={{ marginLeft: '5px' }}>{attendance.Status ? attendance.Status : 'NA'}</span>
                    </div>
                    <div className="text-gray-800 fw-semibold fs-4">
                      Duration: <span className="fw-semibold text-danger" style={{ marginLeft: '5px' }}>{attendance.Duration ? attendance.Duration : 'NA'}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <br />
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-4 col-lg-6">
            <div className="card card-flush h-xl-100">
              <div className="card-header pt-7">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">Upcoming Holidays</span>
                </h3>
              </div>
              <div className="card-body">
                <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: "320px" }}>
                  <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

                    {Array.isArray(holidays) && holidays.length > 0 ? (
                      holidays.map((item, index) => (
                        <div className="border border-gray-200 rounded px-4 py-2 mb-3" key={index}>
                          <div className="d-flex justify-content-between" >
                            <span className="text-gray-800 fw-bold" >
                              <a className=" text-hover-primary fw-bold" style={{
                                color:
                                  colors[index % colors.length],
                              }} >

                                {item?.Reason ? item.Reason : "No Reason Available"}
                              </a>
                            </span>
                            <span className="badge badge-light-info">
                              {item?.HolidayDt ? new Date(item.HolidayDt).toLocaleDateString() : "N/A"}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center">
                        <span className="text-gray-400 fw-bold">
                          No holidays found.
                        </span>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-6" style={{ height: "445px" }}>
            <div className="card card-flush h-xl-100">
              <div className="card-header pt-7">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">Team Pending Leaves</span>
                </h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-3">
                    <thead className="text-start">
                      <tr className=" text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-100px">S.No</th>
                        <th className="min-w-125px">Name</th>
                        <th className="min-w-125px">Date</th>
                        <th className="min-w-125px">Leave Type</th>
                      </tr>
                    </thead>
                    <tbody className="fw-bold text-gray-600 text-start">
                      {currentItems.length > 0 ?
                        currentItems.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{safeReplace(item.UserName)}</td>
                            <td>{safeReplace(formatDate(item.StartDt))}</td>
                            <td>{safeReplace(item.LvType)}</td>
                          </tr>
                        ))
                        :
                        <tr className="text-center fw-semibold">
                          <td colSpan={12}>No Leaves in pending.</td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={teamLeaves.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
