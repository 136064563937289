import { useState } from "react";
import { API_BASE_URL } from "./Config";
import { useNavigate } from "react-router-dom";


export function SignInComponent() {


  const navigate = useNavigate();


  const [formData, setFormData] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSubmit = async (e) => {

    e.preventDefault();

    setErrorMessage('');
    setLoading(true);
    console.log(formData);
    try {
      const response = await fetch(`${API_BASE_URL}rcservice/portallogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputdata: {
            username: formData.username,
            password: formData.password,
          },
        }),
      });
      const data = await response.json();
      console.log(data["ResultData"], 'in signin time');
      if (data.Status) {
        setLoading(false);
        sessionStorage.setItem("userData", JSON.stringify(data["ResultData"][0]));
        navigate("/profile")

      } else {

        setLoading(false);
        setErrorMessage(data.Message);
      }
    } catch (err) {
      console.error("Error during login:", err);

    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    setErrorMessage('');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div
      data-kt-name="metronic"
      id="kt_body"
      className="app-blank app-blank bgi-size-cover bgi-position-center bgi-no-repeat"
      style={{ height: '100vh' }} 
    >
      <div
        className="d-flex flex-column flex-root"
        id="kt_app_root"
        style={{ minHeight: '100vh' }}  
      >
        <style>
          {`
            body {
              background-image: url('assets/media/auth/bg10.jpeg');
              height: 100%;
            }
            [data-theme="dark"] body {
              background-image: url('assets/media/auth/bg10-dark.jpeg');
            }
          `}
        </style>

        <div className="d-flex flex-column flex-lg-row flex-column-fluid" style={{ flexGrow: 1 }}>
          <div className="d-flex flex-lg-row-fluid">
            <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
              <img
                className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                src="assets/media/auth/agency.png"
                alt=""
              />
              <img
                className="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
                src="assets/media/auth/agency-dark.png"
                alt=""
              />
              <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
                Fast, Efficient and Productive
              </h1>
            </div>
          </div>

          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
            <div className="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
              <div className="w-md-400px">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="kt_sign_in_form"
                  action="#"
                  onSubmit={handleSubmit}
                >
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                  </div>

                  <div className="fv-row mb-8">
                    <input
                      type="text"
                      name="username"
                      placeholder="User id"
                      autoComplete="off"
                      value={formData.username}
                      onChange={handleChange}
                      className="form-control bg-transparent"
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control bg-transparent"
                    />
                  </div>

                  <div className="fv-row mb-3 p-0 d-flex align-items-center">
                    <input
                      id="showpassword"
                      type="checkbox"
                      autoComplete="off"
                      checked={isPasswordVisible}
                      onChange={() => setIsPasswordVisible((prevState) => !prevState)}
                    />
                    <label htmlFor="showpassword" className="mx-2">
                      Show Password
                    </label>
                  </div>
                  <p className="text-start text-danger fw-bold">{errorMessage}</p>

                  <div className="d-grid mb-10">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                      {loading ? "Signing in..." : "Sign In"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}